<template>
    <b-card :title="title">
        <div class="flex justify-content-between h4" :style="cssVars">
            <slot name="progress"></slot>
        </div>

        <b-progress class="my-2" v-if="name === 'clicks' && item.quantity.pourcentage" style="position: relative; overflow: visible">
            <b-progress-bar
                :class="getProgressBarClassClics(item.compareClicks, item.totalMetrics.clics, item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)"
                role="progressbar"
                style="border-radius: 99px"
                :value="item.clics.objectivePercentage"
                :max="100"
            ></b-progress-bar>
            <div
                class="vertical-progress-bar"
                role="progressbar"
                :max="100"
                :style="{ left: getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized) + '%' }"
            ></div>
        </b-progress>

        <b-progress class="my-2" v-if="name === 'budget'" style="position: relative; overflow: visible">
            <b-progress-bar
                :class="getProgressBarBudget(item.budgetDifference, item.more.media === 'YOU' ? item.more.price : item.amount_without_margin, item.more.package)"
                style="border-radius: 99px"
                role="progressbar"
                :max="100"
                :value="item.budgetPercentage.toFixed(2) + '%'"
            ></b-progress-bar>
            <div
                class="vertical-progress-bar"
                role="progressbar"
                :max="100"
                :style="{ left: getVerticalBarValue(item.amount_without_margin, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized) + '%' }"
            ></div>
        </b-progress>

        <b-progress class="my-2" v-if="name === 'impressions' && item.quantity.pourcentage" style="position: relative; overflow: visible">
            <b-progress-bar
                :class="getProgressBarClassImpressions(item.compareImpressions, item.totalMetrics.impressions, item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)"
                role="progressbar"
                style="border-radius: 99px"
                :value="item.impressions.objectivePercentage"
                :max="100"
            ></b-progress-bar>
            <div
                class="vertical-progress-bar"
                role="progressbar"
                :max="100"
                :style="{ left: getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized) + '%' }"
            ></div>
        </b-progress>

        <b-progress class="my-2" v-if="name === 'views' && item.quantity.pourcentage" style="position: relative; overflow: visible">
            <b-progress-bar
                :class="getProgressBarClassViews(item.compareViews, item.details.sum_of_views, item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)"
                role="progressbar"
                style="border-radius: 99px"
                :value="item.views.objectivePercentage"
                :max="100"
            ></b-progress-bar>
            <div
                class="vertical-progress-bar"
                role="progressbar"
                :max="100"
                :style="{ left: getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized) + '%' }"
            ></div>
        </b-progress>

        <b-progress class="my-2" v-if="name === 'progression'">
            <b-progress-bar style="background-color: #6c64e2" class="progress-bar" role="progressbar" :value="item.date.pourcentage.toString() + '%'" :max="100"></b-progress-bar>
        </b-progress>

        <slot name="remaining"> </slot>
    </b-card>
</template>

<script>
    import { BCard } from 'bootstrap-vue';
    import { BProgress, BProgressBar, BButton } from 'bootstrap-vue';

    export default {
        props: {
            item: {
                type: Object,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
        },
        components: {
            BCard,
            BProgress,
            BProgressBar,
            BButton,
            BButton,
            BCard,
        },
        data() {
            return {
                percentageColor: 'black',
            };
        },
        mounted() {},
        methods: {
            getVerticalBarValue(objectif, totalDays, days) {
                const daily = (objectif / totalDays) * days;
                const response = (daily / objectif) * 100;

                return Math.floor(response);
            },

            getProgressBarBudget(difference, budget, isPackage) {
                const formattedBudget = Number(budget) / 100;
                const differenceBudget = Math.round((difference / formattedBudget) * 100);

                if (!isPackage) {
                    // Si campagne n'est pas un forfait
                    const SMALL_DIFFERENCE_THRESHOLD = 5;
                    const MEDIUM_DIFFERENCE_THRESHOLD = 20;

                    if (Math.abs(differenceBudget) <= SMALL_DIFFERENCE_THRESHOLD) {
                        this.percentageColor = '#28c76f';
                        return 'bg-success';
                    } else if (Math.abs(differenceBudget) <= MEDIUM_DIFFERENCE_THRESHOLD) {
                        this.percentageColor = '#ff9f43';
                        return 'bg-warning';
                    } else {
                        this.percentageColor = '#ea5455';
                        return 'bg-danger';
                    }
                } else {
                    // Si c'est un forfait
                    const SMALL_POSITIVE_DIFFERENCE_THRESHOLD = 0;
                    const MEDIUM_NEGATIVE_DIFFERENCE_THRESHOLD = -20;

                    if (differenceBudget >= SMALL_POSITIVE_DIFFERENCE_THRESHOLD) {
                        this.percentageColor = '#28c76f';
                        return 'bg-success';
                    } else if (differenceBudget > MEDIUM_NEGATIVE_DIFFERENCE_THRESHOLD) {
                        this.percentageColor = '#ff9f43';
                        return 'bg-warning';
                    } else {
                        this.percentageColor = '#ea5455';
                        return 'bg-danger';
                    }
                }
            },

            getProgressBarClassClics(compareClicks, realized, objectif, daysCampaign, days) {
                const difference = (compareClicks / objectif) * 100;

                const res = this.getProgressBarClass(difference);
                this.percentageColor = res.txt;

                return res.bg;
            },
            getProgressBarClass(difference) {
                switch (true) {
                    case difference < -20:
                        return {
                            bg: 'bg-danger',
                            txt: '#ea5455',
                        };
                    case difference >= 0:
                        return {
                            bg: 'bg-success',
                            txt: '#28c76f',
                        };
                    default:
                        return {
                            bg: 'bg-warning',
                            txt: '#ff9f43',
                        };
                }
            },
            getProgressBarClassImpressions(compareImpressions, realized, objectif, daysCampaign, days) {
                const difference = (compareImpressions / objectif) * 100;
                const res = this.getProgressBarClass(difference);
                this.percentageColor = res.txt;
                return res.bg;
            },

            getProgressBarClassViews(compareViews, realized, objectif, daysCampaign, days) {
                const difference = (compareViews / objectif) * 100;
                const res = this.getProgressBarClass(difference);
                this.percentageColor = res.txt;
                return res.bg;
            },
        },
        computed: {
            cssVars() {
                return {
                    '--txt-color': this.percentageColor,
                };
            },
        },
    };
</script>
<style lang="scss">
    .h4 .perc {
        color: var(--txt-color);
    }

    .vertical-progress-bar {
        background-color: black;
        border-radius: 99px;
        position: absolute;
        width: 4px;
        height: 25px;
        top: -6px;
    }
</style>
