<template>
    <b-card class="card">
        <b-row no-gutters>
            <b-col :class="status"><slot></slot></b-col>
        </b-row>
    </b-card>
</template>

<script>
    import { BCard } from 'bootstrap-vue';
    import successIcon from '@/assets/images/icons/success-icon.png';
    import warningIcon from '@/assets/images/icons/warning-icon.png';
    import dangerIcon from '@/assets/images/icons/danger-icon.png';
    export default {
        props: {
            state: {
                type: Number,
                required: true,
            },
        },
        components: {
            BCard,
        },
        data() {
            return {
                iconImg: '',
                status: '',
            };
        },
        mounted() {
            if (this.state < 0) {
                this.iconImg = successIcon;
                this.status = 'success';
            } else if (this.state > 0 && this.state <= 15) {
                this.iconImg = warningIcon;
                this.status = 'warning';
            } else if (this.state > 15) {
                this.iconImg = dangerIcon;
                this.status = 'danger';
            }
        },
    };
</script>

<style lang="scss">
    .card {
        line-height: 2rem;
    }

    .danger {
        span {
            color: #f63b51;
        }
    }
    .warning {
        span {
            color: #ff9f43;
        }
    }
    .success {
        span {
            color: #28c76f;
        }
    }
</style>
