<template>
    <div id="chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        components: {
            apexchart: VueApexCharts,
        },
        props: {
            value: {
                default: 0,
            },
        },
        data() {
            return {
                chartOptions: {
                    chart: {
                        stacked: true,
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false,
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                    },

                    title: {
                        align: 'left',
                    },

                    grid: {
                        row: {
                            colors: ['transparent'],
                            opacity: 0.5,
                        },
                    },

                    xaxis: {
                        categories: Object.keys(this.value),
                        tickAmount: 6,
                        labels: {
                            rotate: 0,
                            skipOverlapLabels: true,
                            width: '120%',
                        },
                    },
                },
                chartValue: [],
            };
        },
        computed: {
            series() {
                return [
                    {
                        data: this.chartValue ?? 0,
                    },
                ];
            },
        },
        mounted() {
            for (const date in this.value) {
                if (this.value.hasOwnProperty(date) && this.value[date].hasOwnProperty('mediaImpressions')) {
                    this.chartValue.push(this.value[date].mediaImpressions);
                } else if (this.value.hasOwnProperty(date) && this.value[date].hasOwnProperty('mediaClicks')) {
                    this.chartValue.push(this.value[date].mediaClicks);
                }
            }
        },
    };
</script>
